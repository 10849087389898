@import "SCSS/variables.scss";

.appBodyWrapper {
  min-width: $appMinWidth;
  max-width: $appMaxWidth;
  margin: $lh * 4 auto 0;
}

.logout {
  width: 100%;
  height: 1.5 * $lh;
  margin: $lh / 4 0;
  font-size: 1.2rem;
  position: relative;
  bottom: 0px;
  z-index: 1;
}

.LoadingApp {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
