@import "SCSS/variables.scss";
@import "SCSS/mixins.scss";

.Game {
  padding: 0 10px 0;
  min-height: 500px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dice {
  display: flex;
  justify-content: center;
  margin: $lh / 2 auto;

  img {
    width: 100%;
    max-height: 60px;
    max-width: 60px;
    margin: 0 2px 0;
  }

  svg {
    color: var(--dice);
  }
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto $lh;
  max-width: 400px;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    width: 100%;

    p {
      margin-right: 10px;
    }

    &:hover {
      svg {
        @include SpinningDice(1);
      }
    }

    svg {
      margin: 0 5px;
    }
  }
}

.backToLobby {
  display: block;
  text-align: center;
  cursor: pointer;
  line-height: 2 * $lh;

  &:hover {
    text-decoration: underline;
  }
}

.linkText {
  line-break: anywhere;
}

.tableWrapper {
  width: 100%;
  min-height: 480px;

  background-color: rgba(var(--primary-rgb), 0.9);
  border: 1px solid var(--secondary);
  border-radius: 0 10px 10px 0;
}

.mobileTableWrapper {
  border-radius: 10px;
  padding: $lh;
}

.primaryButton {
  width: 100%;
  min-height: $lh * 5;
}

.rtdDie {
  animation-duration: 1.5s;
}

.leftWrapper {
  display: flex;
  flex-direction: column;

  max-height: 480px;
  line-height: 2 * $lh;
  min-width: 200px;
  width: 70%;

  background-color: rgba(var(--primary-rgb), 0.9);
  border: 1px solid var(--secondary);
  border-radius: 10px 0 0 10px;
  margin-right: $lh;

  &.leftWrapperMobile {
    margin: 0 auto;
    border-radius: 10px;
    width: 100%;
  }

  & > div {
    // Players / History Containers
    width: 100%;
    flex-basis: auto;

    &:first-of-type {
      // Players
      margin-bottom: $lh;
      height: 35%;
    }

    &:last-of-type {
      // History
      height: 65%;
    }

    h3 {
      margin: 5px;
      max-height: $lh * 3;
      text-align: center;
    }
  }
}
