@mixin Button($borderColor: var(--secondary)) {
  font-size: 1.2rem;
  line-height: $lh * 2;
  padding-top: $lh;
  padding-bottom: $lh;
  width: 100%;
  cursor: pointer;
  border: 1px solid $borderColor;

  &:hover {
    border: 1px solid var(--primary);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

@mixin Input {
  color: black;
  margin: $lh / 2 auto;
  height: 4 * $lh;
  padding: 0 10px;
  font-size: 1.2rem;
}

@mixin SpinningDice($count: infinite) {
  transform-origin: center center;
  animation-name: spin;
  animation-iteration-count: $count;
}

@mixin JumpingDice($count: infinite) {
  animation-name: jump;
  animation-iteration-count: $count;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(27deg);
  }
  18% {
    transform: rotate(32.4deg);
  }

  33%,
  100% {
    transform: rotate(180deg);
  }
}

@keyframes jump {
  0% {
    transform: translateY(0px);
  }
  15% {
    transform: translateY(-8px);
  }
  18% {
    transform: translateY(-8px);
  }

  33%,
  100% {
    transform: translateY(0px);
  }
}

@mixin UnderlineOnHover {
  line-height: $lh * 2;

  &::after {
    transition: width 0.1s;
    display: block;
    width: 0;
    height: 2px;
    background-color: white;
    content: "";
    position: relative;
    top: 2px;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
}
