@import "SCSS/variables.scss";
@import "SCSS/mixins.scss";

.dropdown {
  & > div > button {
    background-color: transparent;
    color: var(--secondary);

    &.noBorder {
      border: 0px;
    }

    &:hover,
    &:active,
    &:focus {
      color: var(--secondary);
      background-color: transparent !important;
      box-shadow: none;

      svg {
        color: var(--secondary);
      }
    }

    & > svg {
      font-size: 1.5rem;
    }
  }
}
