@import "SCSS/variables.scss";
@import "SCSS/mixins.scss";

div.players,
div.playersMobile {
  .flexColumn {
    overflow: auto;
    height: 83%;
  }

  p {
    padding: 0 $lh;
    margin: 0;
    line-height: $lh * 2.5;
    font-size: 0.8rem;
    cursor: pointer;

    display: flex;
    align-items: center;

    &.viewing {
      background-color: rgba(var(--secondary-rgb), 0.2);
    }

    &.greyOut {
      text-decoration: line-through;
      font-style: italic;
    }
  }

  span {
    margin-right: 5px;
    line-height: $lh * 3;
  }

  svg {
    font-size: 1rem;
    color: var(--host);
    margin-right: 5px;
  }
}

div.playersMobile {
  border-radius: 10px;
  margin: 0 auto;
}
