@import "./variables.scss";

* {
  box-sizing: border-box;
  font-family: $mainFont;
  font-size: 62.5%;
}

body {
  background-color: var(--primary);
  height: 100vh;
  width: calc(100vw - var(--scroll-bar));
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
  margin: 0 auto;
}

table,
tr,
th,
td {
  font-family: $mainFont;
}

p,
a,
span,
input,
button {
  line-height: $lh * 3;
  margin: $lh / 2 0;
  font-family: $mainFont;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p,
a,
span,
input,
h1,
h2,
h3,
h4,
h5,
tr,
div {
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
}

p,
a,
span,
input,
h1,
h2,
h3,
h4,
h5,
div {
  color: var(--secondary);
  border-color: var(--secondary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: $lh * 3;
}

button {
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
  background-color: var(--primary);
  color: var(--secondary);
  border: 1px solid var(--secondary);
  border-color: var(--secondary);

  p {
    color: var(--secondary);
    transition: background-color 0.5s, color 0.5s, border-color 0.5s;
  }

  svg {
    color: var(--secondary);
    transition: background-color 0.5s, color 0.5s, border-color 0.5s;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: var(--secondary);
    color: var(--primary);

    p {
      color: var(--primary);
    }

    svg {
      color: var(--primary);
    }
  }
}
