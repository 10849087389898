@import "SCSS/variables.scss";
@import "SCSS/mixins.scss";

.form {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  max-width: 500px;
  margin: $lh * 6 auto;

  h2 {
    margin: 0 auto;
    font-size: 2rem;
    font-family: $mainFont;
    margin-bottom: $lh * 2;
  }

  & > div {
    display: flex;
    justify-content: space-around;
    align-items: center;

    & > div {
      @media #{$min} {
        max-width: 310px;

        button {
          max-width: 340px;
        }
      }
    }

    &.fContainer {
      width: 100%;
      padding: $lh / 2 0;
    }

    label {
      width: 30%;
      text-align: right;
      line-height: $lh;
      font-size: 1.2rem;
    }

    input {
      @include Input;
    }
  }

  & > div.formInput {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    & > div {
      width: 300px;
    }

    p:first-of-type {
      margin: $lh / 2 0;
    }

    p {
      text-align: center;
      line-height: $lh;
      font-size: 18px;
    }

    input:not(label > input) {
      @include Input;
      width: 100%;
    }

    label {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      input {
        width: 20px;
        margin: 0 0px 0 10px;
      }
    }
  }

  & > div.buttons {
    p {
      line-height: $lh;
      display: flex;
      margin: auto;
    }

    button {
      @include Button;
      border-radius: 0;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
