@import "SCSS/variables.scss";
@import "SCSS/mixins.scss";

.editWrapper {
  max-width: fit-content;
  margin: 0 auto;

  h2 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: $lh;
  }
}

.inputWrapper {
  display: flex;

  input {
    color: black;
    max-width: 300px;
    cursor: pointer !important;
  }

  .prefix {
    min-width: 145px;
    display: flex;
    justify-content: center;
  }
}

.colorBlock {
  width: 25px;
  height: 25px;
}

.primary {
  background-color: var(--primary);
}

.secondary {
  background-color: var(--secondary);
}

.highlight {
  background-color: var(--highlight);
}

.accent {
  background-color: var(--accent);
}

.brightAccent {
  background-color: var(--brightAccent);
}

.error {
  background-color: var(--error);
}

.colorPrompt {
  position: relative;

  & > div {
    position: absolute;
    right: 0px;
    z-index: 3;
    top: -250%;
  }
}

.dropdownWrapper {
  & > div > div > button {
    border-width: 1px;
    border-style: solid;
    border-color: var(--secondary);

    &:hover,
    &:active {
      border-color: var(--brightAccent) !important;
    }
    &:focus {
      border-color: var(--accent) !important;
    }
  }
}

#saveColorsButton,
#saveColorsAsButton {
  display: inline-block;
}

.controlsWrapper {
  display: flex;
  align-items: flex-end;

  & > div:nth-of-type(2) {
    margin-left: $lh;
  }
}

.saveAsWrapper {
  display: flex;

  input {
    width: 75%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > div:first-of-type {
    width: 25%;

    & > button {
      width: 100%;
      min-width: 100px;

      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.unsaved {
  color: var(--error);

  display: flex;
  align-items: center;
  justify-content: center;
}

.reserveSpace {
  height: 170px;
  width: 100%;
}
